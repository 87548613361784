import React from 'react';
import Carousel from '../components/Pendaftaran/Carousel';
import Form from '../components/Pendaftaran/Form';
import Steps from '../components/Pendaftaran/Steps';
import Eligible from '../components/Pendaftaran/Eligible';

const Content = () => {
  return (
    <>
      {/* <KontenSatu /> */}
      <Carousel />
      {/* <Eligible /> */}
      <Steps />
      <Form />
      {/* <Tentang />
      <Media />
      <Faq /> */}
      {/* <Hero /> */}
      {/* <KontenDua /> */}
      {/* <Header /> */}
    </>
  );
};

export default Content;
