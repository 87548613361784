import React from 'react';

import slide0 from '../images/logo-law.jpeg';
// import slide1 from '../images/black.png';

const Content = () => {
  return (
    <section className="text-gray-600 body-font">
  <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-start">
    <div className="lg:flex-grow md:w-1/2 lg:px-32 md:px-28 flex flex-col md:items-start md:text-left items-center text-center mb-8">
    <h1 className="title-font sm:text-xl text-lg mb-4 font-medium text-gray-900 font-serif">Latar Belakang Bu Mercy
      </h1>
      <br />
      {/* Menjadi Tujuan Sekolah Efektif untuk menggali potensi, minat, dan bakat generasi muda Indonesia sehingga dapat bersaing di kancah internasional. Mempersiapkan para peserta didik untuk dapat diterima di perguruan tinggi negeri (PTN) dan perguruan tinggi terbaik dunia. */}
      <p className="mb-8 leading-relaxed">
      Berlatar belakang Sarjana Hukum dan Sarjana IImu Komunikasi.
Lulus sebagai Wisudawan Berprestasi dari Universitas Sumatera Utara Medan dan Universitas Terbuka.
Berpengalaman lebih dari 20 tahun menjadi wartawan di berbagai media massa dan terus aktif menulis di berbagai media online sampai sekarang.
Advokat Dra. Tiurma Mercy Sihombing, S.H. yang akrab dipanggil Bu Mercy mempunyai nilai lebih daripada sekadar menjadi pengacara.
<br /><br />
Sejak masa sekolah, kuliah S1 Hukum dan Ilmu Komunikasi yang dilanjutkan ke S2 di bidang Jurnalistik, Kajian Lingkungan, dan Magister Akuntansi, membuat pengetahuan Bu Mercy menjadi lebih luas dan tidak dibatasi melulu soal hukum. Karena pada dasarnya menurut Bu Mercy, Ilmu Hukum adalah seni mengekspresikan fakta dan harapan untuk membantu kliennya mendapatkan keadilan.
Bahwa selain keterampilan berkomunikasi secara lisan, Advokat dituntut punya keterampilan menulis sehingga dapat mengekspresikan masalah klien dan membuat berbagai berkas hukum dikaitkan dengan litigasi (ber-acara di pengadilan) dan non-litigasi dengan bahasa yang lebih enak dibaca dan tepat dalam pemilihan kata.
<br /><br />
Selain keterampilan menulis, membaca, dan menyampaikan gagasan secara lisan, Bu Mercy yang berpengalaman sebagai Wartawan Harian Kompas, magang di Harian Suara Pembaruan, menjadi Finalis Putri Remaja Indonesia Majalah Gadis, memiliki jejaring luas.
Selain itu, Bu Mercy sering menjadi juara kelas di SD Strada, SMPN 30 Jakarta, SMA PPSP Labschool Rawamangun Jakarta, aktif berorganisasi dan punya segudang prestasi baik seni, budaya, dan olah raga. Bahkan di usia +50 , bersama kedua anaknya, Bu Mercy masih bisa memenangkan berbagai kompetisi teknologi nasional dan internasional.

</p>
    </div>
    <div className="lg:flex-grow md:w-1/2 lg:px-32 md:px-28 flex flex-col md:items-start md:text-left items-center text-center">
    <h1 className="title-font sm:text-xl text-lg mb-4 font-medium text-gray-900 font-serif">Tentang Prestasi Bu Mercy
      </h1>
      {/* <div className="flex items-center justify-center w-full mt-6 lg:mt-0 lg:w-1/2">
        <img className="w-full h-full lg:max-w-2xl rounded-lg shadow-md" src={slide1} />
      </div> */}
      <br />
      {/* Menjadi Tujuan Sekolah Efektif untuk menggali potensi, minat, dan bakat generasi muda Indonesia sehingga dapat bersaing di kancah internasional. Mempersiapkan para peserta didik untuk dapat diterima di perguruan tinggi negeri (PTN) dan perguruan tinggi terbaik dunia. */}
      <p className="mb-8 leading-relaxed">
      Bagi Bu Mercy, Prestasi Terpenting adalah diberikan TUHAN kesempatan mendidik kedua anaknya sejak lahir sampai dewasa. Kesempatan untuk memberikan teladan, integritas, kejujuran, kerja keras, dan kerja cerdas. Untuk mendidik anak, menurut Bu Mercy harus memprioritaskan waktu sebanyak-banyaknya, <i>quantity times</i> lebih berharga daripada <i>quality times</i>, yang biasanya cuma pada <i>week-end</i> atau sisa hari orangtuanya. 
<br /><br />
Hasil tidak pernah mengkhianati proses, putra dan putrinya terbukti sejak dini sudah mencetak segudang prestasi, di bidang ilmu pengetahuan, teknologi, seni, dan olahraga. Ada <i>bocoran</i> juga, kedua anaknya meskipun kuliah di bidang sains, bahasa, dan kebijakan publik, mereka sedang dalam tahap akhir menjadi Sarjana Hukum, seperti mamanya
<br /><br />
Akhir kata, <i>Value</i> (keyakinan atau nilai yang dipegang) sebagai Advokat bagi Bu Mercy adalah
membantu klien sepenuh hati dan sepenuh jiwa menjadi standar pelayanan Bu Mercy. 
<br /><br />
"Apa pun juga yang kamu perbuat, perbuatlah dengan segenap hatimu seperti untuk Tuhan dan bukan untuk manusia" (Kolose 3:23).

</p>
<center>
<div className="flex items-center justify-center w-full mt-6 lg:mt-0 mb-8">
        <img className="w-full h-full lg:max-w-2xl rounded-lg" src={slide0} />
      </div>
      </center>
    </div>
  </div>
</section>


  );
};

export default Content;
