import React from 'react';

// import slide0 from '../images/logo-law.jpeg';
import slide0 from '../images/black.png';
import slide1 from '../images/logo-box.png';

const Content = () => {
  return (
    <header className="bg-yellow-100 dark:bg-gray-800">
  <div className="container px-6 py-16 lg:py-28 mx-auto">
    <div className="items-center lg:flex">
      <div className="w-full lg:w-1/2">
        <div>
          <h1 className="text-sm font-bold text-gray-800 dark:text-white lg:text-2xl font-serif"> Law office of Dra Tiurma Mercy Sihombing, S.H. </h1>
          <br></br>
          <p className="mt-2 text-gray-600 dark:text-gray-400 text-base font-light font-serif">
          Tak kenal maka tak sayang... <br/>
          Berkenalan dengan Bu Mercy
          </p>
          {/* <button className="w-full lg:mr-5 px-5 py-2 mt-6 text-xs font-medium text-black uppercase transition-colors duration-200 transform bg-gray-200 rounded-md lg:w-auto focus:outline-none focus:bg-indigo-500">Konsultasi Online</button> */}
          <a href="https://wa.me/6285695462646" target="_blank"><button className="w-full px-10 py-2 mt-6 text-xs font-medium text-white uppercase transition-colors duration-200 transform bg-gradient-to-r from-yellow-500 to-green-400 hover:from-yellow-400 hover:to-green-300 rounded-md lg:w-auto focus:outline-none focus:bg-indigo-500">WA Bu Mercy</button></a>
        </div>
      </div>
      <div className="flex items-center justify-center w-full mt-6 lg:mt-0 lg:w-1/2">
        <img className="w-full h-full lg:max-w-2xl rounded-lg" src={slide1} />
      </div>
      <div className="flex items-center justify-center w-full mt-6 lg:mt-0 lg:w-1/2">
        <img className="w-full h-full lg:max-w-2xl rounded-lg shadow-md" src={slide0} />
      </div>
    </div>
  </div>
</header>

  );
};

export default Content;
