import React from 'react';
import Carousel from '../components/Belajar/Carousel';
import Tentang from '../components/Tentang';
import Media from '../components/Media';
import Alasan from '../components/Belajar/Alasan';
import Program from '../components/Belajar/Program';
import Cara from '../components/Belajar/Cara';
import Hero from '../components/Hero';
import Faq from '../components/faq';
// import Header from '../components/Header';

const Content = () => {
  return (
    <>
      {/* <KontenSatu /> */}
      <Carousel />
      <Alasan />
      <Program />
      <Cara />
      {/* <Tentang />
      <Media />
      <Faq /> */}
      {/* <Hero /> */}
      {/* <KontenDua /> */}
      {/* <Header /> */}
    </>
  );
};

export default Content;
