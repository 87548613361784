import React from 'react';

const Content = () => {
  return (
<div>
  <section className="text-gray-700 bg-gray-300">
    <div className="container px-5 py-24 mx-auto">
      <div className="text-center mb-20">
        <h1 className="sm:text-3xl text-2xl font-medium font-serif text-center title-font text-gray-800 mb-4">
          Pelayanan Hukum <i>Law Office</i> Of Dra. Tiurma Mercy Sihombing, S.H.
        </h1>
      </div>
      <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
        <div className="w-full lg:w-1/2 px-4 py-2">
          <details className="mb-4">
            <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4 mb-3">
            Bidang Hukum Pidana dan Perdata
</summary>
<span className="p-5">
Hukum Perdata sendiri, lebih mengatur kepada hubungan antar individu di dalam negara.
Namun seringkali hal ini dipengaruhi oleh norma-norma sosial. <br/><br/>
Hukum pidana merupakan ketentuan yang mengatur tindakan apa yang tidak boleh dilakukan. Kemudian, saat tindakan yang tidak diperbolehkan tersebut dilakukan, terdapat sanksi bagi pelakunya. Hukum pidana juga ditujukan untuk kepentingan umum. <br/><br/>
Tambahan Informasi tentang Perbedaan Perkara Perdata dan Perkara Pidana Dalam perkara perdata, inisiatif berperkara berasal dari pihak yang merasa dirugikan.
Sedangkan dalam perkara pidana, inisiatif berperkara berasal dari pihak penguasa negara melalui aparatnya yaitu Polisi dan Jaksa Penuntut Umum.
</span>
          </details>
          <details className="mb-4">
            <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4 mb-3">
            Pelayanan Non Litigasi dan Litigasi
</summary>
<span className="p-5">
Litigasi sendiri yaitu bentuk pelayanan jasa hukum yang proses penyelesaiannya dilakukan
melalui peradilan. Sementara, non litigasi merupakan bentuk pelayanan kuasa hukum dengan cara di luar peradilan. Hal ini bisa dicontohkan sebagai bentuk negosiasi maupun mediasi.<br/><br/>
<strong>Litigasi Perdata</strong> <br/><br/>
Menyediakan layanan litigasi berdasarkan aspek perdata. Layanan meliputi penanganan gugatan perdata yang meliputi Wanprestasi dan Perbuatan Melanggar Hukum.
Melakukan penanganan atas permohonan penetapan. Biasanya hal tersebut meliputi pengesahan anak, pengesahan pernikahan yang masih belum terdaftar pada catatan sipil.
Di samping itu juga berupa pembuatan akta kematian, pengesahan pendewasaan anak maupun izin dan kuasa anak di bawah umur. Juga membantu penanganan perceraian sekaligus harta gono-gini. <br/><br/>
<strong>Litigasi Pidana</strong> <br/><br/>
Memberikan penanganan sekaligus pendampingan hukum pidana umum.
Tujuan utama litigasi pidana adalah agar dapat membawa kasus sengketa menuju pengadilan.
Adanya proses ini juga bisa meliputi pengaduan sekaligus penyelesaian tuntutan maupun penggantian atas kerusakan barang tertentu.
</span>
          </details>
          <details className="mb-4">
            <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4 mb-3">
            Bidang Pertanahan
</summary>
<span className="p-5">
Sengketa tanah yang sedang dialami klien akan diteliti, dicek ke berbagai pihak yang berwenang untuk keabsahan surat tanah dan berkas Alas Hak Tanah. Setelah berkas dikumpulkan dan diteliti, kami memberikan analisis berdasarkan fakta yang sesungguhnya, tidak ada yang ditutup-tutupi, sehingga penyelesaian sengketa tanah baik perorangan maupun perusahaan dapat ditangani sesuai  prinsip dan hukum yang berlaku di Indonesia. 
Sengketa Tanah di Indonesia sering sekali dipersulit ulah oknum Badan Pertanahan Nasional BPN, serta oknum pegawai Kelurahan, Kecamatan, sampai Pemda. Apalagi jika para pihak melibatkan oknum "preman",  oknum "ormas", oknum kepolisian dan tentara yang makin memperkeruh sengketa. Bahkan jika masuk pengadilan, oknum Jaksa Penuntut Umum dan oknum Hakim mungkin ikut terlibat dalam sengketa pertanahan.
<br/>
<br/>
Karena itu untuk mengurai sengketa tanah, Advokat sangat membutuhkan keberanian, kecerdasan, dan kesabaran sehingga masalah sengketa tanah bisa menjadi terang benderang. 
<br/>
<br/>

Pihak yang berhak bisa bersyukur mendapatkan hak; Pihak yang tidak berhak dan tidak jujur harus bertanggung jawab menanggung risiko atas perbuatannya.
</span>
          </details>
        </div>
        <div className="w-full lg:w-1/2 px-4 py-2">
        <details className="mb-4">
            <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4 mb-3">
            Bidang Perburuhan dan Ketenagakerjaan
</summary>
<span className="p-5">
Layanan law firm yang lainnya yaitu berupa perburuhan dan ketenagakerjaan. Hal ini merupakan seperangkat aturan yang mengatur hubungan industrial di antara pengusaha dengan buruh. <br/><br/>
Dengan begitu, masing-masing pihak bisa memahami kewajiban serta hak satu sama lain. Sehingga, ketika memilih firma hukum untuk berkonsultasi, alangkah baiknya apabila Anda telah meneliti dengan baik.
</span>
          </details>
          <details className="mb-4">
            <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4 mb-3">
            Bidang Hukum Teknologi dan Informasi
</summary>
<span className="p-5">
Indonesia mengantisipasi perkembangan komunikasi dunia maya dengan UU ITE yang mengatur segala aspek legal yang berlangsung dalam jejaring internet.
</span>
          </details>
          <details className="mb-4">
            <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4 mb-3">
            Pembela Hak Asasi Perempuan dan Anak
</summary>
<span className="p-5">
Sepertiga populasi Perempuan Indonesia pernah mengalami kekerasan seksual dan fisik.  Demikian  data yang di rilis pemerintah dibantu United Nations Population Fund (UNFPA) Pada Maret 2017. <br/><br/>
Deklarasi tentang Penghapusan Kekerasan Terhadap Perempuan menyatakankekerasan terhadap perempuan merupakan suatu tindakan kekerasan berbasis gender atau bisa mengakibatkan bahaya atau penderitaan fisik, seksual, atau mental perempuan. Bentuk tindak kekerasan tersebut termasuk ancaman tindakan sejenis maupun penelantaran terhadap perempuan dan juga anak.<br/><br/>

Bahwa kasus-kasus terhadap perempuan dan anak lebih memungkinkan terjadi dalam lingkup keluarga baik fisik, maupun mental. Hal itu tentu dapat menganggu tumbuh kembang dan psikis anak.<br/><br/>

Kami memberikan pelayanan pembela hak asasi perempuan dan anak untuk mendapat perlindungan, ke Kepolisian Republik Indonesia serta ke Lembaga Negara lainnya, serta ke Komisi Nasional (Komnas) Perempuan dan Anak. Pelayanan hukum bertujuan untuk menjunjung hak perempuan dan hak anak-anak Indonesia dari Sabang sampai Merauke.<br/><br/>
</span>
          </details>
        </div>
      </div>
    </div>
  </section>
</div>


  );
};

export default Content;
