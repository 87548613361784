import React from 'react';

import slide0 from '../../images/prod/pisa-top.png';

const Content = () => {
  return (
    <header className="bg-indigo-100 dark:bg-gray-800">
  <div className="container px-6 py-16 lg:py-28 mx-auto">
    <div className="items-center lg:flex">
      <div className="w-full lg:w-1/2">
        <img className="w-full h-full lg:max-w-2xl rounded-lg shadow-md" src={slide0} alt="Catalogue-pana.svg" />
      </div>
      <div className="flex items-center justify-center w-full mt-6 lg:mt-0 lg:w-1/2">
        <div className="lg:max-w-lg">
          <h1 className="text-2xl font-semibold text-gray-800 dark:text-white lg:text-3xl"> Sekolah Megana didirikan oleh Lulusan Perguruan Tinggi Negeri dan Luar Negeri dengan mengadopsi cara belajar efektif dan fokus dari Sistem Pendidikan Terbaik di Asia.</h1>
          <br></br>
          <p className="mt-2 text-gray-600 dark:text-gray-400 text-md capitalize">Kurikulum Sekolah Megana merupakan hasil riset selama &gt;5 tahun yang disesuaikan dengan persyaratan penerimaan siswa PTN. 
<br /><br />
Tim akademik kami menggunakan Kurikulum High Order Thinking Skills (HOTS) dari Kementerian Pendidikan dan Kebudayan Riset dan Teknologi RI. Materi Kurikulum HOTS tersebut yang telah diringkas oleh tim mentor Megana sehingga siswa megana mempelajari yang penting dan sering diuji dalam UAS/SBMPTN.</p>
        </div>
      </div>
    </div>
  </div>
</header>

  );
};

export default Content;
