import React from 'react';
import Slider from 'infinite-react-carousel';

import alasan1 from '../images/alasan/1.jpg';
import alasan2 from '../images/alasan/2.jpg';
import alasan3 from '../images/alasan/3.jpg';
import alasan4 from '../images/alasan/4.png';
import alasan5 from '../images/alasan/5.jpg';
import alasan6 from '../images/alasan/6.jpg';

import testimoni1 from '../images/prod/gambar-18.png';
import testimoni2 from '../images/prod/gambar-19.png';

import ptn1 from '../images/ptn/1.png';
import ptn2 from '../images/ptn/2.png';
import ptn3 from '../images/ptn/3.png';
import ptn4 from '../images/ptn/4.png';
import ptn5 from '../images/ptn/5.png';
import ptn6 from '../images/ptn/6.png';
import ptn7 from '../images/ptn/7.png';
import ptn8 from '../images/ptn/8.png';
import ptn9 from '../images/ptn/9.png';
import ptn10 from '../images/ptn/10.png';

const Content = () => {
  return (
    <section className="text-gray-600 body-font bg-gray-100">
      <div align="center">
      <h1 className='lg:text-3xl md:text-1xl sm:text-xl text-base font-serif font-black pt-20 capitalize'>
      Nilai Tambah pada <i>Law Office</i> of Dra. Tiurma Mercy Sihombing, S.H
      </h1>
      </div>
  <div className="container px-5 py-24 mx-auto flex flex-wrap">
    <div className="lg:w-2/3 mx-auto">
      {/* <div className="flex flex-wrap w-full bg-gradient-to-r from-red-400 to-yellow-300 shadow-lg py-32 px-10 relative mb-4"> */}
      {/* <div className="flex flex-wrap w-full bg-white shadow-lg py-32 px-10 relative mb-4">
        <div className="text-center relative z-10 w-full">
          <h2 className="text-2xl text-gray-900 font-medium title-font mb-2 text-indigo-700">Jaminan 100% Lolos Diterima di Perguruan Tinggi Negeri</h2>
          <br />
          <p className="leading-relaxed text-blue-600">SMA Megana mengimplementasikan kurikulum yang mengacu pada standar ujian masuk perguruan tinggi dengan modifikasi dari tim mentor berpengalaman agar para peserta didik mampu beradaptasi dengan semua jenis tes masuk perguruan tinggi negeri seperti Universitas Indonesia, Institut Teknologi Bandung, Universitas Gajah Mada, dan sebagainya</p>
        </div>
      </div> */}
      </div>
      <section className="text-gray-600 body-font">
  <div className="container px-5 py-5 mx-auto">
    {/* <div className="flex flex-col text-center w-full mb-20">
      <h1 className="text-2xl font-medium title-font mb-4 text-gray-900 tracking-widest">OUR TEAM</h1>
      <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably haven't heard of them.</p>
    </div> */}
    <div className="flex flex-wrap -m-4">
      <div className="p-4 lg:w-1/3">
        <div className="h-full flex sm:flex-row flex-col items-start sm:justify-start justify-center text-center sm:text-left">
          {/* <img alt="team" className="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4" src={alasan1} /> */}
          <div className="flex-grow sm:pl-8">
            <h2 className="title-font font-medium text-lg text-gray-900 sm:mt-12">Kesediaan untuk mendengar dalam sesi Konsultasi Hukum</h2>
            {/* <h3 className="text-gray-500 mb-3">UI Developer</h3> */}
            <br />
            <p className="mb-4">Konsultasi Hukum dapat meliputi terkait persoalan tanah, misalnya saja terkait Hukum Agraria yang berlaku di Indonesia.<br/>
Konsultasi Hukum Waris dengan ketentuan Hukum Perdata</p>
          </div>
        </div>
      </div>
      <div className="p-4 lg:w-1/3">
        <div className="h-full flex sm:flex-row flex-col items-start sm:justify-start justify-center text-center sm:text-left">
          {/* <img alt="team" className="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4" src={alasan2} /> */}
          <div className="flex-grow sm:pl-8">
            <h2 className="title-font font-medium text-lg text-gray-900 sm:mt-12">Memiliki Rekanan Pejabat Pembuat Akta Tanah</h2>
            {/* <h3 className="text-gray-500 mb-3">Designer</h3> */}
            <br />
            <p className="mb-4">Pejabat Pembuat Akta Tanah PPAT yang memfokuskan dirinya terhadap pembuatan akta peralihan tanah. PPAT berperan membantu klien melakukan proses jual beli, hibah wasiat, keterangan hak mewarisi dan lain sebagainya.</p>
          </div>
        </div>
      </div>
      <div className="p-4 lg:w-1/3">
        <div className="h-full flex sm:flex-row flex-col items-start sm:justify-start justify-center text-center sm:text-left">
          {/* <img alt="team" className="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4" src={alasan3} /> */}
          <div className="flex-grow sm:pl-8">
            <h2 className="title-font font-medium text-lg text-gray-900 sm:mt-12 capitalize">Memiliki Rekanan Notaris</h2>
            {/* <h3 className="text-gray-500 mb-3">UI Developer</h3> */}
            <br />
            <p className="mb-4">Untuk layanan yang terakhir yaitu dapat meliputi kegiatan notaris.<br/>
Dimana secara garis besar mempunyai tugas utama dalam memberikan pengarahan hingga bentuk konseling yang bertujuan membuat akta. Mulai dari pengurusan Akta Pendirian serta Pembubaran badan hukum berupa PT, koperasi atau lain sebagainya.<br/>
Pelayanan rekanan Notaris juga menciptakan Akta Perubahan Modal Badan Hukum sekaligus pembuatan kesepakatan perjanjian.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    
  </div>
</section>



  );
};

export default Content;
