import React from 'react';

// import slide0 from '../../images/prod/pisa-top.png';

const Content = () => {
  return (
<section className="text-gray-600 body-font">
  <div className="container px-5 py-24 mx-auto flex flex-wrap">
    <div className="flex relative pt-10 pb-20 sm:items-center md:w-2/3 mx-auto">
      <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
        <div className="h-full w-1 bg-gray-200 pointer-events-none" />
      </div>
      <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-yellow-500 text-white relative z-10 title-font font-medium text-sm">1</div>
      <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
          {/* <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">Shooting Stars</h2> */}
          <p className="leading-relaxed">Isi Form di link <a href="https://bit.ly/sekolahmegana2021" target="_blank" className="text-indigo-500">https://bit.ly/sekolahmegana2021 </a></p>
        </div>
      </div>
    </div>
    <div className="flex relative pb-20 sm:items-center md:w-2/3 mx-auto">
      <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
        <div className="h-full w-1 bg-gray-200 pointer-events-none" />
      </div>
      <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-yellow-500 text-white relative z-10 title-font font-medium text-sm">2</div>
      <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
          {/* <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">The Catalyzer</h2> */}
          <p className="leading-relaxed">Tim Admission Sekolah Megana akan menghubungi kontak Whatsapp yang tertera untuk penjadwalan tes minat bakat calon siswa</p>
        </div>
      </div>
    </div>
    <div className="flex relative pb-20 sm:items-center md:w-2/3 mx-auto">
      <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
        <div className="h-full w-1 bg-gray-200 pointer-events-none" />
      </div>
      <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-yellow-500 text-white relative z-10 title-font font-medium text-sm">3</div>
      <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
          {/* <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">The 400 Blows</h2> */}
          <p className="leading-relaxed">Orangtua transfer Rp 500 ribu untuk mendaftarkan anak mengikuti Tes Minat Bakat Sekolah Megana</p>
        </div>
      </div>
    </div>
    <div className="flex relative pb-20 sm:items-center md:w-2/3 mx-auto">
      <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
        <div className="h-full w-1 bg-gray-200 pointer-events-none" />
      </div>
      <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-yellow-500 text-white relative z-10 title-font font-medium text-sm">4</div>
      <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
          {/* <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">The 400 Blows</h2> */}
          <p className="leading-relaxed">Anak mengikuti Tes Minat Bakat pada jadwal yang ditentukan orangtua, anak, dan tim Sekolah Megana.</p>
        </div>
      </div>
    </div>
    <div className="flex relative pb-10 sm:items-center md:w-2/3 mx-auto">
      <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
        <div className="h-full w-1 bg-gray-200 pointer-events-none" />
      </div>
      <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-yellow-500 text-white relative z-10 title-font font-medium text-sm">5</div>
      <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
          {/* <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">Neptune</h2> */}
          <p className="leading-relaxed">Jika lolos, anak resmi menjadi Siswa Sekolah Megana. Siswa akan mendapatkan fasilitas Materi Pembelajaran Online, Materi Pembelajaran Tatap Muka, Sesi Mentoring, Bimbingan Intensif Masuk PTN, Raport, Ijazah, serta konsultasi anak dan orangtua selama 3 tahun sekolah.</p>
        </div>
      </div>
    </div>
  </div>
</section>



  );
};

export default Content;
