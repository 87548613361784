import React from 'react';

import slide0 from '../../images/prod/pisa-top.png';

const Content = () => {
  return (
    <section className="text-gray-600 body-font relative bg-gray-white">
  <div className="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
    <div className="lg:w-2/3 md:w-full bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative py-48 shadow-xl w-full">
      <iframe width="100%" height="100%" className="absolute inset-0" frameBorder={0} title="map" marginHeight={0} marginWidth={0} scrolling="no" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d991.7785690038255!2d106.89049592923159!3d-6.115315166249959!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6a1fefa2086cd3%3A0xc8e63acf0eb05ff4!2sRumah%20MEGANA!5e0!3m2!1sen!2sid!4v1625817115108!5m2!1sen!2sid" />
    </div>
    <div className="lg:w-1/3 md:w-full bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
      <h2 className="text-gray-900 text-2xl mb-1 font-medium title-font">Tanjung Priok</h2>
      <p className="leading-relaxed mb-5 text-gray-600">Rumah Megana, Jl. Kebon Bawang VI No 35, Tanjung Priok, Jakarta Utara, DKI Jakarta</p>
    </div>
  </div>
  <div className="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
    <div className="lg:w-1/3 md:w-full bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
      <h2 className="text-gray-900 text-2xl mb-1 font-medium title-font">Depok</h2>
      <p className="leading-relaxed mb-5 text-gray-600">Liverpool Academy, Pengasinan, Kec. Sawangan, Kota Depok, Jawa Barat 16518</p>
    </div>
    <div className="lg:w-2/3 md:w-full bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative py-48 shadow-xl w-full">
      <iframe width="100%" height="100%" className="absolute inset-0" frameBorder={0} title="map" marginHeight={0} marginWidth={0} scrolling="no" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.6927017496705!2d106.75212901537613!3d-6.433504764704422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69e85b40edb41d%3A0x305ad675932c001a!2sLiverpool%20Academy!5e0!3m2!1sen!2sid!4v1625817153653!5m2!1sen!2sid" />
    </div>
  </div>
  <div className="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
    <div className="lg:w-2/3 md:w-full bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative py-48 shadow-xl w-full">
      <iframe width="100%" height="100%" className="absolute inset-0" frameBorder={0} title="map" marginHeight={0} marginWidth={0} scrolling="no" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.8136991120637!2d106.89149961537396!3d-6.155700862050465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f53f3acc429b%3A0xf83f5d8eb3b300da!2sRuko%20Inkopal%2C%20Klp.%20Gading%20Bar.%2C%20Kec.%20Klp.%20Gading%2C%20Kota%20Jkt%20Utara%2C%20Daerah%20Khusus%20Ibukota%20Jakarta!5e0!3m2!1sen!2sid!4v1625817179244!5m2!1sen!2sid" />
    </div>
    <div className="lg:w-1/3 md:w-full bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
      <h2 className="text-gray-900 text-2xl mb-1 font-medium title-font">Kelapa Gading</h2>
      <p className="leading-relaxed mb-5 text-gray-600">Kelapa Gading, Ruko Inkopal Blok B No 9 (seberang Mall of Indonesia</p>
    </div>
  </div>
  {/* <div className="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
    <div className="lg:w-1/3 md:w-full bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
      <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">Sarinah Building</h2>
      <p className="leading-relaxed mb-5 text-gray-600">Post-ironic portland shabby chic echo park, banjo fashion axe</p>
    </div>
    <div className="lg:w-2/3 md:w-full bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative py-48 shadow-xl w-full">
      <iframe width="100%" height="100%" className="absolute inset-0" frameBorder={0} title="map" marginHeight={0} marginWidth={0} scrolling="no" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.570247196068!2d106.82198061537424!3d-6.188218762354631!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f425e1c6c42d%3A0x3cac8e963b2e37f7!2sGedung%20Sarinah!5e0!3m2!1sen!2sid!4v1625817209938!5m2!1sen!2sid" />
    </div>
  </div> */}
</section>


  );
};

export default Content;
