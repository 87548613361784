import React from 'react';
import Carousel from '../components/Lokasi/Carousel';
// import Header from '../components/Header';

const Content = () => {
  return (
    <>
      {/* <KontenSatu /> */}
      <Carousel />
      {/* <Tentang />
      <Media />
      <Faq /> */}
      {/* <Hero /> */}
      {/* <KontenDua /> */}
      {/* <Header /> */}
    </>
  );
};

export default Content;
