import React from 'react';
import Carousel from '../components/Carousel';
import Tentang from '../components/Tentang';
import Info from '../components/Info';
import Media from '../components/Media';
import Alasan from '../components/Alasan';
import Hero from '../components/Hero';
import Faq from '../components/faq';
// import Header from '../components/Header';

const Home = () => {
  return (
    <>
      {/* <KontenSatu /> */}
      <Carousel />
      <Tentang />
      <Faq />
      <Alasan />
      <Info />
      {/* <Hero /> */}
      {/* <KontenDua /> */}
      {/* <Header /> */}
    </>
  );
};

export default Home;
