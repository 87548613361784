import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'infinite-react-carousel';

import diagram from '../../images/prod/gambar-8.png';
import jadwal1 from '../../images/prod/gambar-11.png';
import jadwal2 from '../../images/prod/gambar-12.png';

import ptn1 from '../../images/ptn/1.png';
import ptn2 from '../../images/ptn/2.png';
import ptn3 from '../../images/ptn/3.png';
import ptn4 from '../../images/ptn/4.png';
import ptn5 from '../../images/ptn/5.png';
import ptn6 from '../../images/ptn/6.png';
import ptn7 from '../../images/ptn/7.png';
import ptn8 from '../../images/ptn/8.png';
import ptn9 from '../../images/ptn/9.png';
import ptn10 from '../../images/ptn/10.png';

const Content = () => {
  return (
    <section className="text-gray-600 body-font bg-gray-100">
      <section className="text-gray-600 body-font bg-white">
  <div className="container px-5 py-24 mx-auto flex flex-wrap">
      <div className="mt-10">
      <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 lg:ml-40 mb-10">Bagaimana Sekolah Megana mempersiapkan Siswa Tembus PTN Impian</h1>
      </div>
      {/* <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">Bagaimana Sekolah Megana mempersiapkan Siswa Tembus PTN Impian</h1> */}
    <div className="flex relative pt-10 pb-20 sm:items-center md:w-2/3 mx-auto">
      <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
        <div className="h-full w-1 bg-gray-200 pointer-events-none" />
      </div>
      <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-gray-500 text-white relative z-10 title-font font-medium text-sm">1</div>
      <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
        <div className="flex-shrink-0 w-24 h-24 bg-gray-100 text-gray-500 rounded-full inline-flex items-center justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-12 h-12">
  <path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z" />
</svg>
        </div>
        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
          <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">Kurikulum Efektif</h2>
          <p className="leading-relaxed text-gray-700 mt-5">Fokus pada 7 mata pelajaran utama Kurikulum Indonesia. <br/><br/> Materi pelajaran sudah diringkas  tim mentor Megana. <br/><br/>Modul tersedia secara ONLINE dan OFFLINE.</p>
        </div>
      </div>
    </div>
    <div className="flex relative pb-20 sm:items-center md:w-2/3 mx-auto">
      <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
        <div className="h-full w-1 bg-gray-200 pointer-events-none" />
      </div>
      <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-gray-500 text-white relative z-10 title-font font-medium text-sm">2</div>
      <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
        <div className="flex-shrink-0 w-24 h-24 bg-gray-100 text-gray-500 rounded-full inline-flex items-center justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" viewBox="0 0 20 20" fill="currentColor">
  <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
</svg>
        </div>
        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
          <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">Metode Mentoring Efektif</h2>
          <p className="leading-relaxed text-gray-700 mt-5">Mentor yang berpengalaman siap membimbing siswa secara small group yakni 1 mentor untuk 5-10  siswa. <br/><br/> Hasilnya siswa lebih fokus dan berani mengutarakan pendapat. <br/><br/> Karena fokus, siswa tidak perlu lagi mengikuti tambahan bimbel.</p>
        </div>
      </div>
    </div>
    <div className="flex relative pb-20 sm:items-center md:w-2/3 mx-auto">
      <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
        <div className="h-full w-1 bg-gray-200 pointer-events-none" />
      </div>
      <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-gray-500 text-white relative z-10 title-font font-medium text-sm">3</div>
      <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
        <div className="flex-shrink-0 w-24 h-24 bg-gray-100 text-gray-500 rounded-full inline-flex items-center justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" viewBox="0 0 20 20" fill="currentColor">
  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
</svg>
        </div>
        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
          <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">Efektif Waktu</h2>
          <p className="leading-relaxed text-gray-700 mt-5">Di sekolah biasa/konvensional, siswa harus menghabiskan 6.480 jam untuk menyelesaikan SMA (belum termasuk waktu untuk bimbel).<br/><br/></p>
        </div>
      </div>
    </div>
    <div className="flex relative pb-10 sm:items-center md:w-2/3 mx-auto">
      <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
        <div className="h-full w-1 bg-gray-200 pointer-events-none" />
      </div>
      <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-gray-500 text-white relative z-10 title-font font-medium text-sm">4</div>
      <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
        <div className="flex-shrink-0 w-24 h-24 bg-gray-100 text-gray-500 rounded-full inline-flex items-center justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" viewBox="0 0 20 20" fill="currentColor">
  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
</svg>
        </div>
        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
          <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">Efektif Biaya</h2>
          <p className="leading-relaxed text-gray-700 mt-5">Sekolah di Megana School menghemat banyak biaya: biaya gedung (&gt;Rp 20 juta), biaya sekolah biasa (&gt;Rp 15 juta), bimbel (&gt;Rp 5 juta), bimbel intensif PTN (&gt;Rp 20 juta), les bahasa Inggris, dan bahkan tidak perlu biaya transportasi siswa karena pembelajaran bisa dilaksanakan dimana saja.</p>
        </div>
      </div>
    </div>
  </div>
</section>
  <section className="text-gray-600 body-font bg-blue-300">
    <section className="text-gray-600 body-font">
  <div className="container px-5 pt-24 mx-auto">
    <div className="lg:w-2/3 flex flex-col sm:flex-row sm:items-center items-start mx-auto">
      <h1 className="flex-grow sm:pr-16 text-2xl font-medium title-font text-gray-900">Mau tahu info lebih lengkap mengenai Megana School?</h1>
      <a className="flex-shrink-0 text-white bg-indigo-500 border-0 py-2 px-4 focus:outline-none hover:bg-indigo-600 rounded text-lg mt-10 sm:mt-0" href="/pendaftaran">Daftar</a>
    </div>
  </div>
</section>
  <div className="container px-5 py-12 mx-auto">
      <div className="w-full" align="center">

  {/* <h1 className='lg:text-5xl md:text-3xl sm:text-2xl text-lg font-black mb-14 capitalize'>
  Testimoni Sekolah Megana
      </h1> */}
      </div>
  {/* <div className="my-10"></div> */}
  <Slider className="py-10">
    <div align="center">
    <img src={diagram} className="shadow-2xl lg:max-w-screen-lg" />
      
    </div>
  </Slider>
  </div>
  </section>
  
</section>

  );
};

export default Content;
