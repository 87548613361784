import React from 'react';

import slide0 from '../images/logo-law.jpeg';

const Content = () => {
  return (
    <section className="text-gray-600 body-font">
  <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-start">
  <div class="lg:grid lg:grid-cols-3">

<div>
  <h1 className="title-font font-serif sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Info Dunia Pengadilan
      </h1>
      <img className="w-full rounded-lg" src={slide0} />
      </div>
      
    <div className="lg:flex-grow lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-start text-center">
      <br />
      {/* Menjadi Tujuan Sekolah Efektif untuk menggali potensi, minat, dan bakat generasi muda Indonesia sehingga dapat bersaing di kancah internasional. Mempersiapkan para peserta didik untuk dapat diterima di perguruan tinggi negeri (PTN) dan perguruan tinggi terbaik dunia. */}
      <p className="mb-8 leading-relaxed">
        <strong class="font-serif capitalize">tata urutan persidangan perkara perdata gugatan di pengadilan negeri</strong> <br/>
        1. Sidang dinyatakan dibuka dan terbuka untuk umum; <br />
2. Para pihak (penggugat dan tergugat) diperintahkan memasuki ruang sidang; <br />
3. Para pihak diperiksa identitasnya (surat kuasanya), demikian pula diperiksa surat ijin praktek dari organisasi advokat; <br />
4. Apabila kedua belah pihak lengkap maka diberi kesempatan untuk menyelesaikan dengan perkara secara damai; <br />
5. Ditawarkan apakah akan menggunakan mediator dari lingkungan PN atau dari luar (lihat Peraturan Mahkamah Agung RI No.1 Tahun 2008); <br />
6. Apabila tidak tercapai kesepakatan damai maka sidang dilanjutkan dengan pembacaan SURAT GUGATAN oleh penggugat/kuasanya: <br />
7. Apabila perdamaian berhasil maka dibacakan dalam persidangan dalam bentuk akta perdamaian yang bertitel DEMI KEADILAN BERDASARKAN KETUHANAN YME: <br />
8. Apabila tidak ada perubahan acara selanjutnya JAWABAN dari tergugat; (jawaban berisi eksepsi, bantahan, permohonan putusan provisionil, gugatan rekonvensi); <br />
9. Apabila ada GUGATAN REKOVENSI, tergugat juga berposisi sebagai penggugat rekonvensi; <br />
10. REPLIK dari penggugat, apabila digugat rekonvensi maka ia berkedudukan sebagai tergugat rekonvensi <br />
11. Pada saat surat menyurat (jawab jinawab) ada kemungkinan ada gugatan intervensi (voeging, vnjwaring, toesenkomst) <br />
12. Sebelum pembuktian ada kemungkinan muncul PUTUSAN SELA (putusan provisionil, putusan tentang dikabulkannya eksepsi absolut, atau ada gugat intervensi); <br />
13. PEMBUKTIAN <br />
14. Dimulai dari penggugat berupa SURAT BUKTI dan SAKSI; <br />
15. Dilanjutkan dari tergugat berupa SURAT BUKTI dan SAKSI; <br />
16. Apabila menyangkut tanah dilakukan PEMERIKSAAN setempat; <br />
17. KESIMPULAN <br />
18. Musyawarah oleh Majelis Hakim (bersifat rahasia) <br />
19. Pembacaan PUTUSAN <br />
20. Isi putusan: a. Gugatan dikabulkan, b. Gugatan ditolak, c. Gugatan tidak dapat diterima; <br />
21. Atas putusan ini para pihak diberitahu hak-haknya apakah akan menerima. pikir-pikir atau akan banding. Apabila pikir-pikir maka diberi waktu selama 14 hari; <br />
22. Dalam hal ada pihak yang tidak hadir maka diberitahu terlebih dahulu dan dalam waktu 14 hari setelah pemberitahuan diberi hak untuk menentukan sikap. Apabila waktu 14 hari tidak menentukan sikap maka dianggap MENERIMA PUTUSAN. <br />

</p>
    </div>
    <div className="lg:flex-grow lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-start text-center">
      <br />
      {/* Menjadi Tujuan Sekolah Efektif untuk menggali potensi, minat, dan bakat generasi muda Indonesia sehingga dapat bersaing di kancah internasional. Mempersiapkan para peserta didik untuk dapat diterima di perguruan tinggi negeri (PTN) dan perguruan tinggi terbaik dunia. */}
      <p className="mb-8 leading-relaxed">
        <strong class="font-serif capitalize">tata urutan persidangan perkara pidana di pengadilan negeri</strong><br/>
        1. Sidang dinyatakan dibuka dan terbuka untuk umum (kecuali perkara tertentu dinyatakan tertutup untuk umum); <br/>
2. Jaksa Penuntut Umum diperintahkan untuk menghadapkan terdakwa ke depan persidangan dalam keadaan bebas; <br/>
3. Terdakwa ditanyakan identitasnya dan ditanya apakah sudah menerima salinan surat dakwaan; <br/>
4. Terdakwa ditanya pula apakah dalam keadaan sehat dan bersedia untuk diperiksa di depan persidangan (kalau bersedia sidang dilanjutkan); <br/>
5. Terdakwa ditanyakan apakah akan didampingi oleh Penasihat Hukum (apabila didampingi apakah akan membawa sendiri, kalau tidak membawa sendiri akan ditunjuk PH oleh Majis Hakim dalam hal terdakwa diancam dengan pidana penjara lima tahun atau lebih/pasal 56 KUHAP ayat (1); <br/>
6. Dilanjutkan pembacaan SURAT DAKWAAN <br/>
7. Atas pembacaan surat dakwaan tadi terdakwa dan atau Penasehat Hukum ditanya akan mengajukan EKSEPSI atau tidak; <br/>
8. Dalam terdakwa/PH mengajukan eksepsi maka diberi kesempatan dan sidang ditunda; <br/>
9. Apabila ada eksepsi dilanjutkan TANGGAPAN JPU atas eksepsi (REPLIK); <br/>
10. Selanjutnya dibacakan PUTUSAN SELA oleh Majelis Hakim; <br/>
11. Apabila EKSEPSI ditolak dilanjutkan pemeriksaan pokok perkara (PEMBUKTIAN) <br/>
12. PEMERIKSAAN SAKSI SAKSI yang diajukan oleh PU (dimulai dari SAKSI KORBAN); <br/>
13. Dilanjutkan SAKSI LAINNYA. <br/>
14. Apabila ada SAKSI YANG MERINGANKAN, dan SAKSI AHLI Witness/expert) <br/>
15. PEMERIKSAAN TERDAKWA <br/>
16. TUNTUTAN (requisitoir); <br/>
17. PEMBELAAN (pledoi); <br/>
18. REPLIK dari Jaksa Penuntut Umum; <br/>
19. DUPLIK <br/>
20. PUTUSAN Majelis Hakim <br/>

</p>
    </div>
      
    </div>
  </div>
</section>


  );
};

export default Content;
