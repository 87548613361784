import React from 'react';

import slide0 from '../../images/prod/pisa-top.png';

const Content = () => {
  return (
    <header className="bg-indigo-100 dark:bg-gray-800">
  <div className="container px-6 py-16 lg:py-28 mx-auto">
    <div className="items-center lg:flex text-center">
      <div className="w-full">
        <div className="">
          <h1 className="text-2xl font-semibold text-gray-800 dark:text-white lg:text-3xl capitalize"> Dengan mendapat jaminan 100% Siswa Masuk PTN, <br/>Bagaimana proses seleksi siswa agar bisa terpilih masuk ke Sekolah Megana?
</h1>
          <br></br>
          <p className="mt-2 text-gray-600 dark:text-gray-400 text-md capitalize lg:px-48">Sekolah Megana menerapkan tes seleksi masuk berupa Tes Minat Bakat yaitu tes dimana SM akan melihat preferensi cara belajar, minat, bakat, serta tujuan studi anak. <br/><br/>Seleksi ini akan menjadi pertimbangan Sekolah Megana dalam memberikan bimbingan bimbingan yang sesuai dengan masing-masing anak untuk dapat masuk ke PTN.</p>
        </div>
      </div>
    </div>
  </div>
</header>

  );
};

export default Content;
