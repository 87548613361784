import React from 'react';

// import slide0 from '../../images/prod/pisa-top.png';

const Content = () => {
  return (
<section className="text-gray-600 body-font relative py-48">
  <div className="absolute inset-0 bg-gray-300">
    <iframe width="100%" height="100%" frameBorder={0} marginHeight={0} marginWidth={0} title="map" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2329.1758155034545!2d106.88963363286759!3d-6.114942856918618!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ee0150989251%3A0x9cb8d1f87abdc9aa!2sRumah%20Megana%20(Mercy%20Smart%20Homeschooling)!5e0!3m2!1sen!2sid!4v1625808755909!5m2!1sen!2sid" style={{filter: 'opacity(0.8)'}} />
  </div>
  <div className="container px-5 py-24 mx-auto flex">
    
  </div>
</section>

  );
};

export default Content;
