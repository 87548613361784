import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import { Switch, Route } from 'react-router-dom';
import Home from './pages';
import Pendaftaran from './pages/pendaftaran';
import Belajar from './pages/belajar';
import Lokasi from './pages/lokasi';
import Footer from './components/Footer';
// import Dropdown from './components/Dropdown';

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const hideMenu = () => {
      if (window.innerWidth > 768 && isOpen) {
        setIsOpen(false);
        console.log('i resized');
      }
    };

    window.addEventListener('resize', hideMenu);

    return () => {
      window.removeEventListener('resize', hideMenu);
    };
  });

  return (
    <>
      {/* <Navbar toggle={toggle} /> */}
      {/* <Dropdown isOpen={isOpen} toggle={toggle} /> */}
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/belajar' component={Belajar} />
        <Route path='/pendaftaran' component={Pendaftaran} />
        <Route path='/lokasi' component={Lokasi} />
      </Switch>
      {/* <Footer /> */}
    </>
  );
}

export default App;
