import React from 'react';
import Slider from 'infinite-react-carousel';

import penilaian1 from '../../images/last/gambar-23.png';
import penilaian2 from '../../images/last/gambar-24.png';

const Content = () => {
    return (
        <section className="text-gray-600 body-font">
  <div className="container px-5 py-24 mx-auto">
    <div className="flex flex-col">
      <div className="h-1 bg-gray-200 rounded overflow-hidden">
        <div className="w-24 h-full bg-yellow-500" />
      </div>
      <div className="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
        <h1 className="sm:w-2/5 text-gray-900 font-medium title-font text-3xl mb-2 sm:mb-0">Cara Belajar</h1>
        {/* <p className="sm:w-3/5 leading-relaxed text-base sm:pl-10 pl-0">Street art subway tile salvia four dollar toast bitters selfies quinoa yuccie synth meditation iPhone intelligentsia prism tofu. Viral gochujang bitters dreamcatcher.</p> */}
      </div>
    </div>
    <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
      <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
        {/* <div className="rounded-lg h-64 overflow-hidden">
          <img alt="content" className="object-cover object-center h-full w-full" src="https://dummyimage.com/1203x503" />
        </div> */}
        <h2 className="text-xl font-medium title-font text-gray-900 mt-5">1. Belajar Mandiri</h2>
        <p className="text-base leading-relaxed mt-2">Pembelajaran dilakukan di rumah murid yang difasilitasi orang tua. Sekolah Megana mengirimkan <strong><i>toolkit</i> modul pembelajaran</strong> yang mencakup teori dan pembahasan untuk 6 (enam) mata pelajaran. Batas waktu pengerjaan dan pengiriman jawaban modul adalah 4 (empat) minggu untuk siswa program standar dan 2 (dua) minggu untuk siswa akselerasi.</p>
      </div>
      <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
        {/* <div className="rounded-lg h-64 overflow-hidden">
          <img alt="content" className="object-cover object-center h-full w-full" src="https://dummyimage.com/1204x504" />
        </div> */}
        <h2 className="text-xl font-medium title-font text-gray-900 mt-5">2. Mentoring Small Group</h2>
        <p className="text-base leading-relaxed mt-2">Siswa mendapat fasilitas diskusi bersama mentor untuk membahas jawaban siswa untuk pelajaran Matematika, IPA, IPS. Mentoring akan dilaksanakan melalui aplikasi Zoom/Google Meet oleh mentor dengan siswa secara sinkronus (bersamaan waktu). <br /><br /> Setelah modul telah selesai dikerjakan, modul dengan jawaban dikirimkan secara online (discreenshoot / discan) dan lewat pos (jawaban tertulis di fotocopy, dikirim ke Sekolah Megana)</p>
      </div>
      <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
        {/* <div className="rounded-lg h-64 overflow-hidden">
          <img alt="content" className="object-cover object-center h-full w-full" src="https://dummyimage.com/1205x505" />
        </div> */}
        <h2 className="text-xl font-medium title-font text-gray-900 mt-5">3. Belajar Luring</h2>
        <p className="text-base leading-relaxed mt-2">Jawaban siswa akan diperiksa dan menjadi bagian nilai raport.<br/><br/>Jika Step 1 dan Step 2 selesai, siswa mendapat Modul Pelajaran berikutnya.</p>
      </div>
    </div>
  </div>
  <section className="text-gray-600 body-font bg-indigo-100">
  <div className="container px-5 py-24 mx-auto">
      <div className="w-full" align="center">

  <h1 className='lg:text-5xl md:text-3xl sm:text-2xl text-lg font-black mb-14 capitalize'>
  Sistem Penilaian Sekolah Megana
      </h1>
      </div>
  <div className="my-10"></div>
  <Slider className="py-10">
    <div align="center">
    <img src={penilaian1} className="shadow-2xl lg:max-w-screen-lg" />
      
    </div>
    <div align="center">
        <img src={penilaian2} className="shadow-2xl lg:max-w-screen-lg" />
      
    </div>
  </Slider>
  </div>
  </section>
  {/* <section className="text-gray-600 body-font">
  <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
    <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
      <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Daftar Sekarang
      </h1>
      <p className="mb-8 leading-relaxed">Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray.</p>
      <div className="flex justify-center">
        <button className="inline-flex text-white bg-yellow-500 border-0 py-2 px-6 focus:outline-none hover:bg-yellow-600 rounded text-lg">Button</button>
        <button className="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">Button</button>
      </div>
    </div>
    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
      <img className="object-cover object-center rounded" alt="hero" src="https://dummyimage.com/720x600" />
    </div>
  </div>
</section> */}


</section>

  );
};

export default Content;
